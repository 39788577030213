<template>
  <section>
    <v-row no-gutters>
      <v-col cols="12">
        <HeroAlt :hero-alt="heroAlt" />
        <v-container>
          <v-row class="py-16">
            <v-col md="12" cols="12">
              <!-- <div class="text-h4 font-weight-black mb-8">Envíanos tus datos y nosotros nos pondremos en contacto contigo.</div> -->
              <FormRegistration />
            </v-col>
          </v-row>
        </v-container>
  
      </v-col>
    </v-row>
  </section>
</template>

<script>
import FormRegistration from "@/components/formRegistration";
import HeroAlt from "@/components/sections/HeroAlt";
export default {
  components: {
    HeroAlt,
    FormRegistration,
  },
  data() {
    return {
      heroAlt: [
        {
          src: "biss-0.jpg",
          heading: " Inscríbete ",
        },
      ],
    };
  },
  head() {
    return {
      title: "Inscríbete",
    };
  },
   mounted() {
    window.scrollTo(0, 0);
  },

};
</script>

<style scoped>
#footer-top {
  display: none !important;
}
</style>

