<template>
  <div>
    <transition name="slide-fade">
      <div v-if="alerta">
        <v-alert text outlined dense :type="tipoAlerta">{{ mensajeAlerta }}</v-alert>
      </div>
    </transition>
    <form ref="guardarDatos" @submit.prevent="guardar" enctype="multipart/formdata">
      <v-row class="mb-n6">
        <v-col cols="12" lg="3">
          <div>
            <input
              type="file"
              accept="image/*"
              ref="inputFileImagen"
              @change="vistaPreviaImagen"
              class="form-control-file"
              name="foto"
              hidden
            />
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-card tile elevation="0" height="200" width="200">
                  <v-img :src="preview" height="200" width="200"></v-img>
                  <v-fade-transition>
                    <v-overlay v-if="hover" absolute color="#1E1E1E">
                      <v-btn
                        class="mr-2"
                        color="primary"
                        @click="quitarImagen"
                        fab
                        :hidden="image != null ? false : true"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>

                      <v-btn fab color="primary" @click="importarFoto">
                        <v-icon>mdi-file-image</v-icon>
                      </v-btn>
                    </v-overlay>
                  </v-fade-transition>
                </v-card>
              </template>
            </v-hover>
            <p class="ml-3" style="color: #e53935">{{ fotoMsg }}</p>
          </div>
        </v-col>

        <v-col>
          <v-text-field
            label="Nombre Completo"
            dense
            outlined
            name="nombreCompleto"
          ></v-text-field>

          <v-text-field label="Dirección" dense outlined name="direccion"></v-text-field>

          <v-text-field
            label="Correo electrónico"
            dense
            outlined
            name="correo"
          ></v-text-field>

          <v-text-field
            label="Teléfono"
            dense
            outlined
            name="telefono"
            type="number"
          ></v-text-field>

          <v-text-field
            label="Grado de estudio"
            dense
            outlined
            name="gradoEstudio"
          ></v-text-field>

          <v-text-field
            label="Especialidad"
            dense
            outlined
            name="especialidad"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-btn
            class="my-2 float-right"
            color="primary"
            type="submit"
            :loading="cargaBoton"
            :disable="cargaBoton"
            >ENVIAR</v-btn
          >
          <v-btn class="my-2 mr-3 float-right" @click="limpiarDatos" :disable="cargaBoton"
            >LIMPIAR</v-btn
          >
        </v-col>
      </v-row>
    </form>
  </div>
</template>

<script>
import utility from "@/js/utility";

export default {
  data: () => ({
    cargaBoton: false,
    alerta: false,
    mensajeAlerta: null,
    tipoAlerta: null,
    intervalo: null,

    preview: "https://icones.pro/wp-content/uploads/2021/02/icone-utilisateur-gris.png",
    fotoMsg: null,
    image: null,
  }),

  methods: {
    vistaPreviaImagen: function (event) {
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        this.image = input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
      this.fotoMsg = "";
    },
    quitarImagen: function () {
      this.image = null;
      this.preview =
        "https://icones.pro/wp-content/uploads/2021/02/icone-utilisateur-gris.png";
      this.$refs.inputFileImagen.value = null;
    },

    importarFoto: function () {
      this.$refs.inputFileImagen.click();
    },

    limpiarDatos: function () {
      this.$refs.guardarDatos.reset();
      this.quitarImagen();
    },

    guardar() {
      this.cargaBoton = true;

      var formData = new FormData(this.$refs.guardarDatos);

      utility
        .agregarInscripcion(formData)
        .then((response) => {
          console.log(response.data);
          switch (response.data.mensaje) {
            case "guardado":
              this.alerta = true;
              this.mensajeAlerta = "¡Listo!, tus datos han sido guardados";
              this.tipoAlerta = "success";
              this.$refs.guardarDatos.reset();
              this.quitarImagen();
              break;

            case "correoRegistrado":
              this.alerta = true;
              this.mensajeAlerta = "El correo ya ha sido registrado";
              this.tipoAlerta = "error";
              break;
            case "errorDeValidacion":
              this.alerta = true;
              this.mensajeAlerta = "Por favor, no deje campos vacios";
              this.tipoAlerta = "error";

              this.fotoMsg = response.data.errores.foto;
              break;
          }
        })
        .finally(() => {
          this.cargaBoton = false;
        });
    },
  },

  watch: {
    alerta(val) {
      if (val) {
        this.intervalo = setInterval(() => {
          this.alerta = false;
          clearInterval(this.intervalo);
        }, 5000);
      }
    },
  },
};
</script>
