<template>
  <section>
    <v-row no-gutters>
      <v-col cols="12">
        <HeroAlt :hero-alt="heroAlt" />
        <v-container>
          <v-row class="py-16">
            <v-col cols="12">
              <h2
                class="text-h4 text-md-h3 text-center font-weight-black text-capitalize"
              >
                Who we are?
              </h2>
              <h3 class="text-h4 text-uppercase font-weight-thin text-center my-8">
                Centro de Formación y Competencias IntegRHales
                <br />
                EJ Partners
              </h3>
            </v-col>
            <v-col cols="12" md="6">
              <p>
                EJ Partners nace el 29 de septiembre del año 2020 en Ciudad del Carmen,
                Campeche México y se conforma legalmente como una asociación civil sin
                fines de lucro con el objetivo de formar especialistas de todas las partes
                del mundo.
              </p>
              <blockquote class="text-h4 pa-5 my-4 font-weight-thin">
                …Actualmente contamos con una plantilla de entrenadores en México y
                Estados unidos.
              </blockquote>
              <p>
                Avalados por un consejo técnico de especialistas en la docencia y el
                desarrollo humano, hemos creado entrenamientos de calidad mundial para
                agrupar, no sólo a coaches, sino a profesionales del crecimiento y el
                cambio personal.
              </p>
            </v-col>
            <v-col cols="12" md="6">
              <p>
                El resultado de nuestro esfuerzo y dedicación ha hecho que desde que se
                formó esta asociación a la fecha, ya podemos contar con más de 15
                especialistas egresados en México y Estados Unidos, tanto coaches como
                especialistas en PNL y consultores en Felicidad.
                <br />
                <br />
                Nuestros valores principales son el ejemplo y la congruencia. Tratamos de
                hacer la diferencia a través de la preparación continua y el respeto por
                nuestra filosofía basada en la conexión humana.
              </p>
            </v-col>
          </v-row>
        </v-container>
        <Team :our-team="ourTeam" />

        <v-row no-gutters>
          <v-col cols="12" md="6" align-self="center">
            <v-img
              max-height="800"
              src="@/assets/pexels-canva-studio-3277806.jpg"
              lazy-src="pexels-canva-studio-3277806.jpg"
            >
            </v-img>
          </v-col>
          <v-col cols="12" md="6" align-self="center">
            <div class="pa-lg-16 pa-md-10 px-4 py-16">
              <h2 class="text-h3 text-center font-weight-black">Nuestra Visión</h2>
              <h3 class="text-h5 text-uppercase font-weight-thin text-center my-8">
                El único modo de hacer un gran trabajo es amar lo que haces.
                <br />-Steve Jobs
              </h3>
              <p>
                Ser reconocidos a nivel internacional como un centro de formación de
                referencia en el desarrollo personal, agrupadora de excelentes agentes
                profesionales del cambio, líderes de opinión, pero, sobre todo, seres
                humanos de excelencia.
              </p>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters class="flex-row-reverse">
          <v-col cols="12" md="6" align-self="center">
            <v-img
              max-height="800"
              src="@/assets/pexels-rfstudio-3810792.jpg"
              lazy-src="pexels-rfstudio-3810792.jpg"
            >
            </v-img>
          </v-col>
          <v-col cols="12" md="6" align-self="center">
            <div class="pa-lg-16 pa-md-10 px-4 py-16">
              <h2 class="text-h3 text-center font-weight-black">Nuestra Misión</h2>
              <h3 class="text-h5 text-uppercase font-weight-thin text-center my-8">
                La primera regla del éxito es una buena preparación
                <br />-George B. Shaw
              </h3>
              <p>
                Entrenar, agrupar y proyectar a profesionales al desarrollo del potencial
                humano, tomando en cuenta siempre el factor diferenciador basado en la
                calidad y la calidez humana de todos nuestros miembros.
              </p>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import HeroAlt from "@/components/sections/HeroAlt";
import Team from "@/components/sections/Team";

export default {
  components: {
    HeroAlt,
    Team,
  },
  mounted() {
    window.scrollTo(0, 0);
  },

  data() {
    return {
      heroAlt: [
        {
          src: "partners-group.jpg",
          heading: " Quienes somos ",
        },
      ],
      ourTeam: [
        {
          name: "Erik Jimenez",
          position: "Fundador",
          phone: "(981) 166 1189",
          email: "ejimenez@ejpartners.com",
          photo: "person-1.jpg",
        },
        {
          name: "Isabel May",
          position: "Consejo",
          phone: "(981) 166 1189",
          email: "imay@ejpartners.com",
          photo: "person-2.jpg",
        },
        {
          name: "Jorge Che",
          position: "Desarrollo de Software",
          phone: "(938) 132 5630",
          email: "jche@ejpartners.com",
          photo: "person-3.jpg",
        },
      ],
    };
  },
  head() {
    return {
      title: "Quienes somos",
      meta: [
        {
          hid: "description",
          name: "description",
          content:
            "Infographic hypotheses influencer user experience Long madel ture gen-z paradigm shift client partner network product seilans solve management influencer analytics leverage virality. incubator seed round massmarket. buyer agile development growth hacking business-to-consumer ecosystem",
        },
      ],
    };
  },
};
</script>
